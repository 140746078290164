import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"
import "./main.css"
import "./flicker.css"
import DVMAImage from "../images/dvma.png"

const values = [
  "duvel mon amour",
  "duvel mon amour",
  "duvel mon amour",
  // "deviens mon ailleurs",
  "devine mon avenir",
  "dévoue mes attentes",
  "divine mon apéro",
  "deviens mon ami",
  "diva mais aimable",
  "divise mes apports",
  "divulgue mes atouts",
  "déviant mais altruiste",
  // "dolce vita mi amor",
  "dix vagues mal aplaties",
  "dix vieux mâles abjects",
  "duvel mon amour",
  "déverse mes allocs",
  // "dv - a",
  "des vins moins aigres",
  "des voies mènent ailleurs",
  "demain vous marcherez",
  "demain vous matcherez",
  "des villes mieux agencées",
  "des vieux moins abîmés",
  "des vieilles mieux aimées",
  // "douces vieilles males automatiques",
  // "des vacances mais amusantes",
  "dire vrai mais avant",
  "duvel mon amour",
  // "des vieilles males anglaises",
  "Davout meets Aboukir",
  "devin mode avancé",
  "divague mais à l'aise",
  "des vaches methanées",
  "des vomis avoués",
]

export default function Home() {
  const [index, setIndex] = useState(0)
  useEffect(() => {
    const i = setInterval(() => {
      setIndex(index => (index + 1) % values.length)
    }, 5000)
    return () => clearInterval(i)
  }, [])
  // const content = values[index]
  const content = "DVMA"

  // ★★	☆	☆	☆

  // https://soundcloud.com/dvmamusic/

  return (
    <div>
      <Helmet title="DVMA" />
      <div id="container">
        <div class="sign">
          {content.split("").map((word, i) => (
            <span
              key={i}
              class={
                word === "d" || word === "v" || word === "m" || word === "a"
                  ? ""
                  : "fast-flicker"
              }
            >
              {word !== " " ? word : <span>&nbsp;&nbsp;</span>}
            </span>
          ))}
        </div>

        <img src={DVMAImage} />

        <div className="iframe">
          <div style={{ margin: 10 }} align="center">
            <div style={{ color: "white" }}>DARK ELECTRO PAILLETTES</div>
          </div>
        </div>

        <div className="iframe">
          <div style={{ margin: 10 }} align="center">
            <div style={{ color: "white", marginTop: 10 }}>
              Entre Nils Frahm, Rebotini
            </div>
            <div>Il construit des machines</div>
            <div>Il construit des claviers</div>
            <div style={{ color: "white" }}>Pour nous faire danser</div>
            <div>{`Jusqu'au bout de la nuit`}</div>
          </div>
        </div>

        <a href="https://www.instagram.com/dvma.be/" target="_blank">
          <div className="iframe">
            <div style={{ margin: 10 }} align="center">
              <div style={{ color: "white" }}>INSTAGRAM</div>
              <div>@DVMA.be</div>
            </div>
          </div>
        </a>

        <div class="sign" style={{ fontSize: "32pt", marginTop: 30 }}>
          SHOWS
        </div>

        <div className="iframe">
          <div style={{ margin: 10 }} align="center">
            <ul
              style={{
                textAlign: "left",
                margin: 0,
                padding: 0,
              }}
            >
              <li>▸ 01/03 PARIS</li>
              <li>▸ 01/04 PARIS</li>
              <li>▸ 07/05 NANTES</li>
              <li>▸ 18/05 MARSEILLE</li>
              <li>▸ 24/05 PARIS</li>
              <li>▸ 21/06 PARIS</li>
              <li>▸ 22/06 CREST</li>
              <li>▸ 24/06 MARSEILLE</li>
              <li>▸ 25/06 BOFFRES</li>
              <li>▸ 01/07 NANTES</li>
              <li>▸ 07/07 TOURNAN, LA FERME ÉLECTRIQUE</li>
            </ul>
          </div>
        </div>

        <div class="sign" style={{ fontSize: "52pt", marginTop: 30 }}>
          BOOKING
        </div>
        <div className="iframe">
          <a
            href="mailto:contact@dvma.be"
            style={{
              color: "rgb(255, 0, 183)",
              margin: 12,
              fontSize: "16pt",
              fontWeight: "bold",
            }}
          >
            CONTACT@DVMA.BE
          </a>
        </div>

        <div class="sign" style={{ fontSize: "32pt", marginTop: 30 }}>
          SOUNDCLOUD
        </div>

        <div className="iframe">
          <a
            href="https://soundcloud.com/dvmamusic/"
            target="_blank"
            style={{
              color: "rgb(255, 0, 183)",
              margin: 12,
              fontSize: "16pt",
              fontWeight: "bold",
            }}
          >
            SOUNDCLOUD.COM/DVMAMUSIC
          </a>
        </div>

        <div class="sign" style={{ fontSize: "32pt", marginTop: 30 }}>
          BANDCAMP
        </div>

        <div className="iframe">
          <a
            href="https://dvmamusic.bandcamp.com"
            target="_blank"
            style={{
              color: "rgb(255, 0, 183)",
              margin: 12,
              fontSize: "16pt",
              fontWeight: "bold",
            }}
          >
            DVMAMUSIC.BANDCAMP.COM
          </a>
        </div>

        <div className="iframe">
          <iframe
            width="560"
            height="315"
            style={{ maxWidth: "100%" }}
            src="https://www.youtube.com/embed/bQkm7dyaC0w"
            title="DVMA POPPERS AND ARPEGGIATORS"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <div className="iframe">
          <div>
            <iframe
              style={{ border: 0, width: "100%", height: 120 }}
              src="https://bandcamp.com/EmbeddedPlayer/album=1256648923/size=large/bgcol=333333/linkcol=fe7eaf/tracklist=false/artwork=small/track=1215858490/transparent=true/"
              seamless
            >
              <a href="https://dvmamusic.bandcamp.com/album/poppers-arpeggiators">
                STORIE MACABRE
              </a>
            </iframe>
          </div>
          <div>
            <iframe
              style={{ border: 0, width: "100%", height: 120 }}
              src="https://bandcamp.com/EmbeddedPlayer/album=1256648923/size=large/bgcol=333333/linkcol=fe7eaf/tracklist=false/artwork=small/track=297489644/transparent=true/"
              seamless
            >
              <a href="https://dvmamusic.bandcamp.com/album/poppers-arpeggiators">
                DÉTENDS-TOI
              </a>
            </iframe>
          </div>
          <div>
            <iframe
              style={{ border: 0, width: "100%", height: 120 }}
              src="https://bandcamp.com/EmbeddedPlayer/album=1256648923/size=large/bgcol=333333/linkcol=fe7eaf/tracklist=false/artwork=small/track=1426059128/transparent=true/"
              seamless
            >
              <a href="https://dvmamusic.bandcamp.com/album/poppers-arpeggiators">
                AMSTERDAM
              </a>
            </iframe>
          </div>
          <div>
            <iframe
              style={{ border: 0, width: "100%", height: 120 }}
              src="https://bandcamp.com/EmbeddedPlayer/album=1256648923/size=large/bgcol=333333/linkcol=fe7eaf/tracklist=false/artwork=small/track=1758468433/transparent=true/"
              seamless
            >
              <a href="https://dvmamusic.bandcamp.com/album/poppers-arpeggiators">
                MICHEL
              </a>
            </iframe>
          </div>
          <div>
            <iframe
              style={{ border: 0, width: "100%", height: 120 }}
              src="https://bandcamp.com/EmbeddedPlayer/album=1256648923/size=large/bgcol=333333/linkcol=fe7eaf/tracklist=false/artwork=small/track=736878047/transparent=true/"
              seamless
            >
              <a href="https://dvmamusic.bandcamp.com/album/poppers-arpeggiators">
                GIANNA
              </a>
            </iframe>
          </div>
        </div>

        <div className="iframe">
          <div>
            <iframe
              style={{ border: 0, width: "100%", height: 522 }}
              src="https://bandcamp.com/EmbeddedPlayer/album=2062883561/size=large/bgcol=333333/linkcol=fe7eaf/transparent=true/"
              seamless
            >
              <a href="https://dvmamusic.bandcamp.com/album/ombrage-demo">
                Ombrage - DEMO by DVMA
              </a>
            </iframe>
          </div>
        </div>
      </div>
    </div>
  )
  /*return (
    <div>
          <Helmet title="DVMA" />
          <div id="container">
            <div class="sign">
              {content.split('').map((word, i) => (
                <span key={i} class={i % 2 === 0 ? "fast-flicker" : ""}>{word !== ' ' ? word : <span>&nbsp;&nbsp;</span>}</span>
              ))}
            </div>



          </div>
        </div>
        )*/
}
